<template>
    <div class="searchResultPage">

        <!-- 搜索栏 -->
        <div class="searchBar">
            <!-- 搜索框 -->
            <div class="searchBox">
                <img class="searchIco" src="@/assets/png/searchBoxIco.png" />
                <!-- <van-field type="search" @keydown.enter="getSearchList('refresh')" v-model="searchValue" placeholder="动漫/漫画/角色扮演 50000+" center clearable /> -->
                <form action="JavaScript:return true;" class="searchForm">
                  <van-field @keydown.enter="getSearchList('refresh')" v-model="searchValue" placeholder="动漫/漫画/角色扮演 50000+" center>
                      <template #button>
                          <div @click.stop="clearInput" v-if="searchValue">
                              <svg-icon class="closeBtn" iconClass="searchClose" />
                          </div>
                          <div v-else></div>
                      </template>
                  </van-field>
                </form>
            </div>
            <div class="searchBtn" @click="$router.go(-1)">
                <span>取消</span>
            </div>
        </div>

        <van-tabs class="navTabs oepnX" swipeable animated line-width="24" color="rgb(113,69,231)" line-height="6" v-model="activeName"
                  background="rgba(0,0,0,0)" v-if="navList && navList.length" @change="clickTag">
            <van-tab v-for="(item,index) in navList" :key="index">
                <template #title>
                      <span class="navTabTitle" :class="{'navTabTitleActive':activeName == index}">
                          {{item.title}}
                      </span>
                </template>
                <div class="bookshelfContent">
                    <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                                 @onRefresh="onRefresh" :isNoData="isNoData" :error="error" :is-higeht-max="true"
                                 :adv-list="advList" :hasAdvPagination="true" className="HotRankingPage">
                        <!-- 横板 -->
                        <div v-if="activeName == 2">

                            <div v-if="videoList && videoList.length > 0">
                                <TransverselyTwoList :isEdit="isEdit" :videoList="videoList"></TransverselyTwoList>
                            </div>

                            <!-- 猜你想看 -->
                            <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                                <div class="guessTitle">
                                    <span>猜你想看</span>
                                </div>
                                <TransverselyTwoList :videoList="videoHotList"></TransverselyTwoList>
                            </div>
                        </div>
                        <!-- 竖版 -->
                        <div v-else>
                            <div v-if="videoList && videoList.length > 0">
                                <VerticleThreeList :isEdit="isEdit" :videoList="videoList"></VerticleThreeList>
                            </div>

                            <!-- 猜你想看 -->
                            <div v-if="videoHotList && videoHotList.length > 0" class="guess">
                                <div class="guessTitle">
                                    <span>猜你想看</span>
                                </div>
                                <VerticleThreeList :videoList="videoHotList"></VerticleThreeList>
                            </div>
                        </div>
                    </PullRefresh>
                    <Loading v-else />
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import {queryHotInfoList, querySearchList} from "@/api/video";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import store from "@/store";
import {setSearchKey} from "@/utils/getConfig";
import { Toast } from "vant";

export default {
    name: "Bookshelf",
    components:{
        PullRefresh,
        Loading,
        VerticleThreeList,
        TransverselyTwoList
    },
    data(){
        return {
            searchValue:"",
            pageNumber:1,
            pageSize:20,
            activeName:0,
            topicId:"",
            zone:"",
            titleName:"",
            videoList:[],
            videoHotList:[],
            isLoading:true,
            loading: true,
            refreshing: false,
            finished: false,
            isNoData: false,
            advList: [],
            error: false,
            delItem: null,
            navList:[
                {
                    title:"动漫",
                    kind: 0
                },
                {
                    title:"漫画",
                    kind: 1
                },
                {
                    title:"真人",
                    kind: 3
                },
                {
                    title:"写真",
                    kind: 5
                },
                {
                    title:"小说",
                    kind:4
                },
                {
                    title:"原作",
                    kind:6
                }
            ],
            isTypeLoading : 0 ,   //书籍加载，如果加载完，就加载推荐（0：加载书籍数据，1：加载推荐数据）
            isEdit: false
        }
    },
    mounted() {
        let query = this.$route.query;
        if (query){
            this.searchValue = query.searchValue;
            this.getSearchList('refresh');
        }
        // this.getHotInfoList('refresh');
    },methods:{
        clearInput() {
            this.searchValue = "";
        },
        /**
         * 获取搜索结果
         */
        async getSearchList(type) {

            if(!this.searchValue){
                return;
            }

            setSearchKey(this.searchValue);


            this.$bus.$emit("dataBuriedEvent",{
                "dataType":2,
                tagsName: this.searchValue
            });

            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                kind: this.navList[this.activeName].kind,
                keyword: this.searchValue
            }
            let res = await this.$Api(querySearchList, req);

            const {data} = res;
            console.log(data);
            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            try {
                if(res && res.code == 200){
                    if(data.MediaList && data.MediaList.length > 0){
                        if(type == "refresh"){
                            this.videoList = data.MediaList;
                        }else {
                            this.videoList = this.videoList.concat(data.MediaList);
                        }
                    }else{
                        this.videoList = [];
                    }

                    // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
                    //     this.isNoData = true;
                    // }
                    if (!data.hasNext || data.MediaList.length < req.pageSize) {
                        // this.finished = true;
                        this.isTypeLoading = 1;
                        this.getHotInfoList('refresh');
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        },async getHotInfoList(type) {
            /**
             * 猜你喜欢
             * @type {{pageNumber: number, kind: number, pageSize: number}}
             */
            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                kind: this.navList[this.activeName].kind
            }
            let res = await this.$Api(queryHotInfoList, req);
            const {data} = res;
            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            try {
                if (res && res.code == 200) {

                    if(data.MediaList && data.MediaList.length > 0){
                        if(type == "refresh"){
                            this.videoHotList = data.MediaList;
                        }else {
                            this.videoHotList = this.videoHotList.concat(data.MediaList);
                        }
                    }

                // if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
                //     this.isNoData = true;
                // }
                // if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
                //     this.finished = true;
                // }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        },onRefresh() {
            this.pageNumber = 1;
            this.refreshing = true;
            this.finished = false;
            this.isNoData = false;
            this.getSearchList('refresh');
            // this.getHotInfoList('refresh');
        }
        ,onLoad(){
            this.loading = true;
            if (this.error) {
                this.error = false;
            } else {
                this.pageNumber++
            }
            if(this.isTypeLoading == 0){
                this.getSearchList();
            }else{
                this.getHotInfoList();
            }
        },
        clickTag(index) {
          console.log(this.activeName, index);
          this.videoHotList = [];
          if (this.videoHotList.length === 0) {
              // this.videoHotList = [];
              this.activeName = index;

              this.pageNumber = 1;
              this.finished = false;
              this.loading = true;
              this.isLoading = true;
              this.isNoData = false;
              this.getSearchList('refresh');
            } else {
              Toast("数据没加载完成请等待！");
            }
          },
          // this.videoHotList = [];
          // if(index != null){
          //     this.activeName = index;
          // }
          // this.pageNumber = 1;
          // this.finished = false;
          // this.loading = true;
          // this.isLoading = true
          // this.isNoData = false;
          // this.getSearchList('refresh');
          // this.getHotInfoList('refresh');
          // }
    }
}
</script>

<style lang="scss" scoped>
.searchResultPage{
    height: 100%;
    .closeBtn {
        width: 22px;
        height: 22px;
        margin-right: 4px;
        display: inline-block;
        vertical-align: middle;
    }
    .searchForm {
        width: 100%;
    }
    .searchBar{
        padding: 8px 0 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .searchBox{
            display: flex;
            align-items: center;
            width: calc(100% - 24px - 72px);
            height: 30px;
            background: rgb(230,230,230);
            //background: red;
            border-radius: 18px;
            padding: 0 12px;
            margin-right: 12px;

            .searchIco{
                width: 17px;
                height: 17px;
            }

            /deep/ .van-cell{
                //border-radius: 18px;
                width: 100%;
                height: 100%;
                padding: 0;
                background: rgb(230,230,230);
                //background: green;
                margin-left: 7px;
                align-items: center;
            }

            /deep/ .van-field__control{
                color: rgb(0,0,0);
                font-size: 12px;
            }

            /deep/ .van-field__control::placeholder{
                color: rgb(153,153,153);
                font-size: 12px;
            }
        }

        .searchBtn{
            font-size: 16px;
            color: rgb(153,153,153);
            margin-right: 24px;
        }

    }

    .navTabs{
        .navTabTitle{
            font-size: 15px;
            color: rgb(153,153,153);
            font-weight: 100;
        }

        .navTabTitleActive{
            font-weight: 800;
            color: #000;
        }

        ///deep/ .van-tabs__nav--complete {
        //    padding-left: 12px;
        //}
        //
        ///deep/ .van-tabs__wrap--scrollable .van-tab{
        //    padding: 0;
        //    padding-right:21px;
        //}
        //
        /deep/ .van-tabs__wrap{
            height: 44px;
            // margin-bottom: 12px;
            display: flex;
        }

        /deep/ .van-tabs__line{
          width: 18px !important;
            height: 2px !important;
            bottom: 22px !important;
            background-color: rgba(251, 209, 61, 1) !important;
        }

        /deep/ .van-tab{
            padding: 0;
            width: 40px;
            margin-right: 18px;
        }

        .bookshelfContent{
            height: calc(100vh - 48px - 12px - 40px);
            padding: 12px 12px 0 12px;
            overflow-y: auto;
            background: #F5F5F5;

            .guess{

                .guessTitle{
                    color: rgb(0,0,0);
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 9px;
                }
            }
        }
    }

    .content{
        //width: 100%;
        height: calc(100% - 44px);
        padding: 0 12px;
    }

    .delDialog{

        /deep/ .van-dialog{
            background: rgb(255,255,255);
        }

        .delDialogBtn{
            width: 10.6px;
            height: 10.6px;
            position: absolute;
            right: 12px;
            top: 12px;
        }

        /deep/ .van-button--default{
            background: rgb(255,255,255);
        }

        /deep/ .van-dialog__cancel{
            color: rgb(153,153,153);
        }

        /deep/ .van-hairline--left{
            color: rgb(113,69,231);
        }

        /deep/ [class*=van-hairline]::after{
            border: 1px solid rgb(233,233,233);
        }

        .dialogContent{
            font-size: 18px;
            color: rgb(0,0,0);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 18px 70px;
        }

    }
}
</style>
